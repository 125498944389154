import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useReducer } from 'react';
// utils
import axios from 'axios';
import { API_END_POINTS } from 'src/utils/axios';

//
import { ROLES, UserDetails } from 'src/utils/utils';
import { AuthContext } from './auth-context';
import { setSession } from './utils';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  // const initialize = useCallback(async () => {
  //   try {
  //     const accessToken = localStorage.getItem(STORAGE_KEY);

  //     if (accessToken && isValidToken(accessToken)) {
  //       setSession(accessToken);

  //       const response = await axios.get(endpoints.auth.me);

  //       const { user } = response.data;

  //       dispatch({
  //         type: 'INITIAL',
  //         payload: {
  //           user: {
  //             ...user,
  //             accessToken,
  //           },
  //         },
  //       });
  //     } else {
  //       dispatch({
  //         type: 'INITIAL',
  //         payload: {
  //           user: null,
  //         },
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     dispatch({
  //       type: 'INITIAL',
  //       payload: {
  //         user: null,
  //       },
  //     });
  //   }
  // }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        setSession(accessToken);

        dispatch({
          type: 'INITIAL',
          payload: {
            user: {
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: 'INITIAL',
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (data) => {
    // const response = await axios.post(API_END_POINTS.AUTH.LOGIN, data);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/login`, {
      email: data.identifier,
      password: data.password,
    });
    // const { jwt: accessToken, user } = response.data;
    const { token: accessToken, user } = response.data;
    const userData = {
      ...user,
      role: user?.user_role,
      user_name: `${user.firstName} ${user.lastName}`,
      value: user?.user_role === ROLES.superAdmin ? 'prant' : 'vibhag',
      // ...UserDetails.find((r) => r.role === ROLES.superAdmin),
    };
    localStorage.setItem('user', JSON.stringify(userData));

    setSession(accessToken);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          // ...user,
          accessToken,
        },
      },
    });
  }, []);

  // REGISTER
  const register = useCallback(async ({ payload: data, from }) => {
    // const response = await axios.post(API_END_POINTS.AUTH.REGISTER, data);
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}api/register`, {
      ...data,
      interest: data.interest.split(','),
      otherTypesOfActivitiesChecked: false,
      otherTypesOfActivities: '',
      responsibleForFair: data.responsible_for_fair,
      role: 7,
      aadharNumber: data.aadhar_number,
      aadharImage: data.aadhar_image,
      districtName: data.jillaName,
      vibhagName: ' ',
    });

    if (from === 'user-registration') {
      // const { jwt: accessToken, user } = response.data;
      // const userData = {
      //   ...user,
      //   role: user.user_role,
      //   user_name: `${user.firstName} ${user.lastName}`,
      //   value: user.user_role === ROLES.superAdmin ? 'prant' : 'vibhag',
      // };
      // localStorage.setItem('user', JSON.stringify(userData));
      // setSession(accessToken);
      // dispatch({
      //   type: 'REGISTER',
      //   payload: {
      //     user: {
      //       // ...user,
      //       accessToken,
      //     },
      //   },
      // });
    }
  }, []);
  // REGISTER
  const newRegister = useCallback(async ({ payload: data }) => {
    const response = await axios.post(API_END_POINTS.AUTH.NEW_REGISTER, data);
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      newRegister,
      logout,
    }),
    [login, logout, newRegister, register, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
